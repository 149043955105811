
<template>
    <b-container fluid>
        <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('cotton_config.unit_assign') }}</h4>
            </template>
            <template v-slot:body>
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.office_type')"
                            label-for="office_type_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.office_type_id"
                                :options="officeTypeList"
                                id="office_type_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('globalTrans.office')"
                            label-for="office_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.office_id"
                                :options="officeList"
                                id="office_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.region')"
                            label-for="region_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.region_id"
                                :options="regionList"
                                id="region_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            :label="$t('cotton_config.zone')"
                            label-for="zone_id"
                            >
                            <b-form-select
                                plain
                                v-model="search.zone_id"
                                :options="zoneList"
                                id="zone_id"
                                >
                                <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                        <b-form-group
                        class="row"
                        label-cols-sm="3"
                        :label="$t('cotton_config.unit')"
                        label-for="unit_name"
                        >
                        <b-form-select
                            plain
                            v-model="search.unit_id"
                            :options="unitList"
                            id="unit_id"
                            >
                            <template v-slot:first>
                            <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                            </template>
                        </b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6">
                        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search') }}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('cotton_config.unit_assign') + ' ' + $t('globalTrans.all') }}</h4>
                    </template>
                    <template v-slot:headerAction>
                        <router-link to="unit-assign-form" class="btn btn_add_new">
                            <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                        </router-link>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(user_id)="data">
                                            <strong>{{ currentLocale === 'en' ? data.item.name_en : data.item.name_bn }}</strong><br>
                                            <span>{{ $t('globalTrans.mobile') }}</span>: {{ data.item.mobile_no | mobileNumber }} <br/>
                                            <span>{{ $t('globalTrans.email') }}</span>: {{ data.item.email }}
                                        </template>
                                        <template v-slot:cell(region_ids)="data">
                                            {{ getRegionNames(data.item.region_ids) }}
                                        </template>
                                        <template v-slot:cell(zone_ids)="data">
                                            {{ getZoneNames(data.item.zone_ids) }}
                                        </template>
                                        <template v-slot:cell(unit_ids)="data">
                                            {{ getUnitNames(data.item.unit_ids) }}
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import RestApi, { agriMarketingServiceBaseUrl } from '@/config/api_config'
import { unitAssignList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  data () {
    return {
      search: {
        office_type_id: 0,
        office_id: 0,
        region_id: 0,
        zone_id: 0,
        unit_id: 0
      },
      zoneList: [],
      unitList: [],
      rows: [],
      officeList: []
    }
  },
  computed: {
    officeTypeList () {
        return this.$store.state.commonObj.officeTypeList.filter(item => item.org_id === 10 && item.status === 0 && item.text_en.toLowerCase() !== 'head office')
    },
    regionList () {
        return this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1)
    },
    currentLocale () {
        return this.$i18n.locale
    },
    districtList () {
        return this.$store.state.commonObj.districtList.filter(item => item.status === 0)
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('cotton_config.unit_entry') : this.$t('cotton_config.unit') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
        const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-center', thStyle: { width: '7%' } },
          { label: this.$t('globalTrans.office_type'), class: 'text-center' },
          { label: this.$t('globalTrans.office'), class: 'text-center' },
          { label: this.$t('cotton_config.name_mobile_email'), class: 'text-center' },
          { label: this.$t('cotton_config.region'), class: 'text-center', thStyle: { width: '13%' } },
          { label: this.$t('cotton_config.zone'), class: 'text-center', thStyle: { width: '13%' } },
          { label: this.$t('cotton_config.unit'), class: 'text-center', thStyle: { width: '13%' } }
        ]

        let keys = []

        if (this.$i18n.locale === 'bn') {
            keys = [
            { key: 'index' },
            { key: 'office_type_name_bn' },
            { key: 'office_name_bn' },
            { key: 'user_id' },
            { key: 'region_ids' },
            { key: 'zone_ids' },
            { key: 'unit_ids' }
            ]
        } else {
            keys = [
            { key: 'index' },
            { key: 'office_type_name' },
            { key: 'office_name' },
            { key: 'user_id' },
            { key: 'region_ids' },
            { key: 'zone_ids' },
            { key: 'unit_ids' }
            ]
        }

        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    }
  },
  watch: {
    'search.office_type_id': function (newVal, oldVal) {
        this.officeList = this.getOfficeList(newVal)
    },
    'search.region_id': function (newVal, oldVal) {
        this.zoneList = this.getZoneList(newVal)
    },
    'search.zone_id': function (newVal, oldVal) {
        this.unitList = this.getUnitList(newVal)
    },
    currentLocale: function (newVal, oldVal) {
      if (newVal) {
        this.zoneList = this.getZoneList(this.search.region_id)
        this.unitList = this.getUnitList(this.search.zone_id)
      }
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    edit (item) {
      this.editItemId = item.id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(agriMarketingServiceBaseUrl, unitAssignList, params).then(response => {
        if (response.success) {
            this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
            this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const officeTypeList = this.$store.state.commonObj.officeTypeList
      const officeList = this.$store.state.commonObj.officeList

      const listData = data.map(item => {
        const officeTypeObject = officeTypeList.find(officeType => officeType.value === item.office_type_id)
        const officeObject = officeList.find(office => office.value === item.office_id)
        const officeTypeData = { office_type_name: officeTypeObject.text_en, office_type_name_bn: officeTypeObject.text_bn }
        const officeData = { office_name: officeObject.text_en, office_name_bn: officeObject.text_bn }

        return Object.assign({}, item, officeTypeData, officeData)
      })
      return listData
    },
    getOfficeList (id) {
        return this.$store.state.commonObj.officeList.filter(item => item.office_type_id === parseInt(id))
    },
    getRegionList (orgId) {
      const regionList = this.$store.state.agriMarketing.commonObj.regionList.filter(item => item.status === 1 && item.org_id === orgId)
      return regionList.map(item => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn }
          } else {
              return { value: item.value, text: item.text }
          }
      })
    },
    getZoneList (regionId) {
        const zoneList = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => item.region_id === regionId)
        return zoneList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text }
            }
        })
    },
    getUnitList (zoneId = null) {
        const unitList = this.$store.state.agriMarketing.commonObj.unitList.filter(item => item.status === 1)
        if (zoneId) {
            return unitList.filter(unit => unit.zone_id === parseInt(zoneId))
        }
        return unitList
    },
    getRegionName (id) {
        const obj = this.$store.state.agriMarketing.commonObj.regionList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getRegionNames (idArr) {
        let names = ''
        if (idArr && idArr.length) {
            const list = this.$store.state.agriMarketing.commonObj.regionList.filter(item => idArr.includes(item.value))
            const nameEnArr = []
            const nameBnArr = []
            list.forEach(item => {
                nameEnArr.push(item.text_en)
                nameBnArr.push(item.text_bn)
            })
            names = this.currentLocale === 'en' ? nameEnArr.toString() : nameBnArr.toString()
        }
        return names
    },
    getZoneNames (idArr) {
        let names = ''
        if (idArr && idArr.length) {
            const list = this.$store.state.agriMarketing.commonObj.zoneList.filter(item => idArr.includes(item.value))
            const nameEnArr = []
            const nameBnArr = []
            list.forEach(item => {
                nameEnArr.push(item.text_en)
                nameBnArr.push(item.text_bn)
            })
            names = this.currentLocale === 'en' ? nameEnArr.toString() : nameBnArr.toString()
        }
        return names
    },
    getUnitNames (idArr) {
        let names = ''
        if (idArr && idArr.length) {
            const list = this.$store.state.agriMarketing.commonObj.unitList.filter(item => idArr.includes(item.value))
            const nameEnArr = []
            const nameBnArr = []
            list.forEach(item => {
                nameEnArr.push(item.text_en)
                nameBnArr.push(item.text_bn)
            })
            names = this.currentLocale === 'en' ? nameEnArr.toString() : nameBnArr.toString()
        }
        return names
    },
    getZoneName (id) {
        const obj = this.$store.state.agriMarketing.commonObj.zoneList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getUnitName (id) {
        const obj = this.$store.state.agriMarketing.commonObj.unitList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getOfficeName (id) {
        const obj = this.$store.state.commonObj.officeList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    },
    getDesignationName (id) {
        const obj = this.$store.state.commonObj.designationList.find(item => item.value === parseInt(id))
        return this.currentLocale === 'en' ? obj?.text_en : obj?.text_bn
    }
  }
}
</script>
